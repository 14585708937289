import { TaxIdType } from '@localstack/types';
import { EU_COUNTRIES } from '@localstack/constants';

// partially generated enum and regular expressions out of examples:
// https://stripe.com/docs/billing/customer/tax-ids#using-api
export const TAX_ID_TYPE_REGEX_MAP = {
  [TaxIdType.AU_ABN]: /^\d{11}$/,
  [TaxIdType.AU_ARN]: /^\d{10}\d{2}$/,
  [TaxIdType.EU_VAT]: [
    /^ATU\d{8}$/, // Austria
    /^BE\d{10}$/, // Belgium
    /^BG\d{10}$/, // Bulgaria
    /^HR\d{11}$/, // Croatia
    /^CY\d{8}[A-Z]$/, // Cyprus
    /^CZ\d{8,10}$/, // Czech Republic
    /^DK\d{8}$/, // Denmark
    /^EE\d{9}$/, // Estonia
    /^FI\d{8}$/, // Finland
    /^FR[A-Z0-9]{2}\d{9}$/, // France
    /^DE\d{9}$/, // Germany
    /^EL\d{9}$/, // Greece
    /^HU\d{11}$/, // Hungary
    /^IE\d{7}[A-Z]$/, // Ireland (old format)
    /^IE\d{7}[A-Z]{2}$/, // Ireland (new format)
    /^IT\d{11}$/, // Italy
    /^LV\d{11}$/, // Latvia
    /^LT\d{9,12}$/, // Lithuania
    /^LU\d{8}$/, // Luxembourg
    /^MT\d{8}$/, // Malta
    /^NL\d{9}B\d{2}$/, // Netherlands
    /^PL\d{10}$/, // Poland
    /^PT\d{9}$/, // Portugal
    /^RO\d{10}$/, // Romania
    /^SK\d{10}$/, // Slovakia
    /^SI\d{8}$/, // Slovenia
    /^ES[A-Z0-9]\d{7}[A-Z0-9]$/, // Spain
    /^SE\d{12}$/, // Sweden
    /^XI\d{9}$/, // Northern Ireland
  ],
  [TaxIdType.BR_CNPJ]: /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/,
  [TaxIdType.BR_CPF]: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
  [TaxIdType.CA_BN]: /^\d{9}$/,
  [TaxIdType.CA_GST_HST]: /^\d{9}[A-Z]{2}\d{4}$/,
  [TaxIdType.CA_PST_BC]: /^[A-Z]{3}-\d{4}-\d{4}$/,
  [TaxIdType.CA_PST_MB]: /^\d{6}-\d$/,
  [TaxIdType.CA_PST_SK]: /^\d{7}$/,
  [TaxIdType.CA_QST]: /^\d{10}[A-Z]{2}\d{4}$/,
  [TaxIdType.CL_TIN]: /^\d{2}\.\d{3}\.\d{3}-[A-Z]$/,
  [TaxIdType.HK_BR]: /^\d{8}$/,
  [TaxIdType.IN_GST]: /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z0-9]{1}[A-Z0-9]{1}[A-Z0-9]{1}$/,
  [TaxIdType.ID_NPWP]: /^\d{2}\.\d{3}\.\d{3}\.\d-\d{3}\.\d{3}$/,
  [TaxIdType.IL_VAT]: /^\d{9}$/,
  [TaxIdType.JP_CN]: /^\d{10}\d{3}$/,
  [TaxIdType.JP_RN]: /^\d{5}$/,
  [TaxIdType.KR_BRN]: /^\d{3}-\d{2}-\d{5}$/,
  [TaxIdType.LI_UID]: /^[A-Z]{3}\d{9}$/,
  [TaxIdType.MY_FRP]: /^\d{8}$/,
  [TaxIdType.MY_ITN]: /^[A-Z] \d{10}$/,
  [TaxIdType.MY_SST]: /^[A-Z]\d{2}-\d{4}-\d{8}$/,
  [TaxIdType.MX_RFC]: /^[A-Z]{3}\d{6}[A-Z]{2}\d$/,
  [TaxIdType.NZ_GST]: /^\d{9}$/,
  [TaxIdType.NO_VAT]: /^\d{9}[A-Z]{3}$/,
  [TaxIdType.RU_INN]: /^\d{10}$/,
  [TaxIdType.RU_KPP]: /^\d{9}$/,
  [TaxIdType.SA_VAT]: /^\d{10}\d{5}$/,
  [TaxIdType.SG_GST]: /^[A-Z]\d{8}[A-Z]$/,
  [TaxIdType.SG_UEN]: /^\d{9}[A-Z]$/,
  [TaxIdType.ZA_VAT]: /^\d{10}$/,
  [TaxIdType.ES_CIF]: /^[A-Z]\d{8}$/,
  [TaxIdType.CH_VAT]: /^CHE-\d{3}\.\d{3}\.\d{3} MWST$/,
  [TaxIdType.TW_VAT]: /^\d{8}$/,
  [TaxIdType.TH_VAT]: /^\d{10}\d{3}$/,
  [TaxIdType.AE_TRN]: /^\d{10}\d{5}$/,
  [TaxIdType.GB_VAT]: /^GB\d{9}$/,
  [TaxIdType.US_EIN]: /^\d{2}-\d{7}$/,
};

const normalizedRegexMap = (taxIdCode: string, countryCode: string): [string, RegExp[]][] =>
  Object.entries(TAX_ID_TYPE_REGEX_MAP)
    // some EU countries (like Spain) have internal + EU vat numbers
    .filter(([key]) => key.toUpperCase().startsWith(taxIdCode) || key.toUpperCase().startsWith(countryCode))
    // transform all regular expressions to lists of regular expressions
    .map(([key, val]) => [key, val instanceof Array ? val : [val]]);

export const getTaxIdType = (country: Optional<string>, taxId: Optional<string>): Optional<TaxIdType> => {
  if (!country || !taxId) return null;

  const countryCode = country.toUpperCase();
  const isEuCountry = EU_COUNTRIES.includes(countryCode);
  const taxIdCode = isEuCountry ? TaxIdType.EU_VAT.toUpperCase() : countryCode;

  const regexPairs: [string, RegExp[]][] = normalizedRegexMap(taxIdCode, countryCode);
  const regexPair = regexPairs.find(([, regexps]) => regexps.some((regex) => regex.test(taxId)));

  if (regexPair) return regexPair[0] as TaxIdType;
  return null;
};

export const getTaxIdExample = (country: Optional<string>): Optional<TaxIdType> => {
  if (!country) return null;

  const countryCode = country.toUpperCase();
  const isEuCountry = EU_COUNTRIES.includes(countryCode);
  const taxIdCode = isEuCountry ? TaxIdType.EU_VAT.toUpperCase() : countryCode;

  const regexPairs: [string, RegExp | undefined][] = normalizedRegexMap(taxIdCode, countryCode).map(
    ([key, val]) => [key, isEuCountry ? val.find((regex) => regex.toString().startsWith(`/${countryCode}`)) : val[0]],
  );

  const regexPair = regexPairs[0];

  if (!regexPair || !regexPair[1]) return null;

  // 48-57 = 0-9, 65-90=A-Z, 97-122=a-z
  const buildStr = (to: number, sequence: [number, number] = [65, 90]) => {
    const startCharCode = sequence[0];
    const endCharCode = sequence[1];
    const symbolsToGenerate = to === 0 ? 1 : to;

    return new Array(symbolsToGenerate).fill(null).reduce(
      (str, _, charIdx) => {
        // char that is supposed to be next, i.e. 65+0=A, 65+1=B, ...
        const charCode = startCharCode + charIdx;
        // correct char code in case of an overflow, i.e. 65+25=Z, 65+26=A, 65+27=B, ...
        const correctedCharCode = charCode > endCharCode ? startCharCode + (endCharCode % charCode) - 1 : charCode;
        return str + String.fromCharCode(correctedCharCode);
      },
      '');
  };

  // 99 to 0 range of numbers
  const scanRange = new Array(99).fill(0).map((_, idx) => idx).reverse();
  return scanRange.reduce(
    (memo, val) => memo
      .replace(/^\//, '')
      .replace(/\/$/, '')
      .replace(/(\^|\$)/, '')
      .replaceAll(`[A-Z]${val === 0 ? '' : `{${val}}`}`, buildStr(val))
      .replaceAll(`[A-Z0-9]${val === 0 ? '' : `{${val}}`}`, buildStr(val))
      .replaceAll(`[a-z]${val === 0 ? '' : `{${val}}`}`, buildStr(val, [97, 122]))
      .replaceAll(`\\d${val === 0 ? '' : `{${val}}`}`, buildStr(val, [48, 57]))
      .replaceAll('\\.', '.')
      .replaceAll('\\/', '/'),
    regexPair[1].toString(),
  ) as TaxIdType;
};
