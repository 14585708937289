import { DEFAULT_REDSHIFT_ROUTES } from '@localstack/constants';
import { ReactElement, useMemo } from 'react';
import { RedshiftCreateClusterMessage, RedshiftModifyClusterMessage } from '@localstack/types';
import { Breadcrumbs, RedshiftClusterForm, ProgressButton } from '@localstack/ui';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { Box, Card, CardActions, CardContent, Typography } from '@mui/material';

import { useParams } from 'react-router-dom';

import { RedshiftProps } from './types';

export const RedshiftClusterUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_REDSHIFT_ROUTES,
}: RedshiftProps): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();
  const { data: clusters } = useAwsGetter('Redshift', 'describeClusters', [{ ClusterIdentifier: id }], {
    clientOverrides,
  });

  // cluster type and the types used to modify a cluster don't always match, so they must be updated to match
  const clusterInfo = useMemo(() => {
    const cluster = clusters?.Clusters?.[0];
    if (cluster && id) {
      return {
        ...cluster,
        ClusterIdentifier: id,
        MultiAZ: cluster.MultiAZ !== 'disabled',
        ClusterSecurityGroups:
          cluster.ClusterSecurityGroups?.flatMap((sg) =>
            sg.ClusterSecurityGroupName ? [sg.ClusterSecurityGroupName] : [],
          ) ?? [],
        IamRoles: cluster.IamRoles?.flatMap((role) => (role.IamRoleArn ? [role.IamRoleArn] : [])) ?? [],
      };
    }
    return undefined;
  }, [clusters, id]);

  const { createCluster, modifyCluster, isLoading } = useAwsEffect('Redshift', ['createCluster', 'modifyCluster'], {
    clientOverrides,
    revalidate: ['describeClusters'],
  });

  const handleCreate = async (data: RedshiftCreateClusterMessage) => {
    if (await createCluster(data)) {
      goto(routes.RESOURCES_REDSHIFT_CLUSTERS);
    }
  };

  const handleUpdate = async (data: RedshiftModifyClusterMessage) => {
    if (await modifyCluster(data)) {
      // in the case of renaming the cluster we want to see the details under the new cluster id as route
      const idToGoTo = data.NewClusterIdentifier && id !== data.NewClusterIdentifier ? data.NewClusterIdentifier : id;
      goto(routes.RESOURCES_REDSHIFT_CLUSTER, { id: idToGoTo });
    }
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Cluster Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Redshift', () => goto(routes.RESOURCES_REDSHIFT_CLUSTERS)],
              [id, () => goto(routes.RESOURCES_REDSHIFT_CLUSTER, { id })],
              [clusterInfo ? 'Update Cluster' : 'Create Cluster', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          color="primary"
          variant="outlined"
          type="submit"
          form="RedshiftClusterUpsert"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <RedshiftClusterForm
            formId="RedshiftClusterUpsert"
            onSubmit={clusterInfo ? handleUpdate : handleCreate}
            clusterInfo={clusterInfo}
            loading={isLoading}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            color="primary"
            variant="outlined"
            type="submit"
            form="RedshiftClusterUpsert"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
