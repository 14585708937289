import { ReactElement, useState } from 'react';
import { ButtonProps, Button, LinearProgress, styled } from '@mui/material';

const ButtonWrapper = styled('div')<{ $fullWidth?: boolean }>(({ $fullWidth }) => ({
  display: 'inline-block',
  position: 'relative',
  ...($fullWidth && { width: '100%' }),
}));

const StyledLinearProgress = styled(LinearProgress)(() => ({
  position: 'absolute',
  bottom: 0,
  width: '100%',
}));

export type ProgressButtonProps = ButtonProps & {
  loading?: boolean;
};

export const ProgressButton = ({
  loading,
  children,
  disabled,
  onClick,
  ...rest
}: ProgressButtonProps): ReactElement => {
  const [isPromisePending, setIsPromisePending] = useState<boolean | undefined>(false);
  const isLoading = loading !== undefined ? loading : isPromisePending;

  return (
    <ButtonWrapper $fullWidth={rest.fullWidth}>
      <Button
        disabled={isLoading || disabled}
        onClick={async (event) => {
          if (loading === undefined) {
            setIsPromisePending(true);
            await onClick?.(event);
            setIsPromisePending(false);
          } else {
            onClick?.(event);
          }
        }}
        {...rest}
      >
        {children}
      </Button>
      {isLoading && <StyledLinearProgress />}
    </ButtonWrapper>
  );
};

export default ProgressButton;
