import { AwsClientOverrides, getSchema } from '@localstack/services';
import { RedshiftCreateClusterMessage, RedshiftModifyClusterMessage } from '@localstack/types';
import { ReactElement } from 'react';
import { DEFAULT_IAM_ROUTES } from '@localstack/constants';

import { MagicForm } from '../../magic/MagicForm';
import { RelatedResourcePicker } from '../../../form/RelatedResourcePicker';

export type RedshiftClusterFormProps = {
  /** the cluster information necessary to pass to the modifyCluster method */
  clusterInfo?: RedshiftModifyClusterMessage;
  formId: string;
  onSubmit: (data: RedshiftCreateClusterMessage | RedshiftModifyClusterMessage) => void;
  loading?: boolean;
  clientOverrides?: AwsClientOverrides;
};

export const RedshiftClusterForm = ({
  clusterInfo,
  formId,
  onSubmit,
  loading,
  clientOverrides,
}: RedshiftClusterFormProps): ReactElement => (
  <MagicForm
    entry={clusterInfo ? 'ModifyClusterMessage' : 'CreateClusterMessage'}
    schema={getSchema('Redshift')}
    loading={loading}
    externalFields={{
      '^IamRoles\\.\\d$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client="IAM"
          method="listRoles"
          arrayKeyName="Roles"
          property="Arn"
          fieldName={fieldName}
          entityName="IAM Roles"
          required={required}
          clientOverrides={clientOverrides}
          creationRoute={DEFAULT_IAM_ROUTES.RESOURCES_IAM_ROLE_CREATE}
        />
      ),
      '^DefaultIamRoleArn$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client="IAM"
          method="listRoles"
          arrayKeyName="Roles"
          property="Arn"
          fieldName={fieldName}
          entityName="Default IAM Role"
          required={required}
          clientOverrides={clientOverrides}
          creationRoute={DEFAULT_IAM_ROUTES.RESOURCES_IAM_ROLE_CREATE}
        />
      ),
    }}
    onSubmit={onSubmit}
    formId={formId}
    data={clusterInfo}
  />
);
