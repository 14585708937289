import {
  useApiGetter,
  ExtensionService,
  useLocalstackStatus,
  getSelectedInstance,
  satisfiesVersionConstraint,
  useExtensionHandler,
} from '@localstack/services';

import { ReactElement, useMemo } from 'react';
import { Grid, Typography, Alert } from '@mui/material';
import { PlanFamily } from '@localstack/types';
import { LoadingFragment } from '@localstack/ui';
import { useLocation } from 'react-router-dom';

import { ExternalLink } from '@localstack/constants';

import { CustomerLayout } from '~/layouts';

import { NotRunningAlert } from '~/components';

import { ExtensionCard, gridItemBreakpoints } from './components/ExtensionCard';


export const ExtensionsManage = (): ReactElement => {
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const queryParamExtension = queryParams.get('extension');

  const instance = getSelectedInstance();
  const clientOverrides = instance ? { endpoint: instance.endpoint } : {};

  const { running, version, isPro } = useLocalstackStatus(clientOverrides);

  const { data: publishedExtensions, isLoading: loadingPublishedExtensions, hasError: hasPublishedExtensionsError } =
    useApiGetter(ExtensionService, 'listExtensionsMarketplace', [], { defaultValue: [] });

  const extensions = useMemo(() => ([...publishedExtensions ?? []]?.sort((a, b) =>
    (b.created ?? 0) - (a.created ?? 0)) ?? []), [publishedExtensions]);

  const {
    install: onInstall,
    uninstall: onUninstall,
    isExtensionInstalled,
    isManagingExtension: managingExtension,
    hasManagingExtensionError,
    displayedExtensions,
    lastInteractedExtension: lastExtension,
    logs,
    mutatingExtension,
  } = useExtensionHandler({ extensions, clientOverrides });

  const PublishedExtensionAlert = hasPublishedExtensionsError ?
    'An error occurred while fetching available extensions' : undefined;

  const hasVersionMismatch = !satisfiesVersionConstraint(version || '', '2.2');
  const isLocalStackRunningOrRebooting = running || mutatingExtension;

  const VersionMismatchAlert = hasVersionMismatch ?
    'Please upgrade LocalStack to version 2.3 or newer to install extensions via this UI' : undefined;

  const NotProInstanceAlert = running && !isPro ?
    'Please start the LocalStack Pro edition to manage extensions via this UI' : undefined;

  const showAlert = isLocalStackRunningOrRebooting &&
    (PublishedExtensionAlert || VersionMismatchAlert || NotProInstanceAlert);

  const disableManage = !running || !!mutatingExtension || !!VersionMismatchAlert || !!NotProInstanceAlert;

  const sortedDisplayedExtensions = useMemo(() => displayedExtensions?.sort((a, b) =>
    Number(isExtensionInstalled(b.name)) - Number(isExtensionInstalled(a.name)),
  ), [displayedExtensions]);

  return (
    <CustomerLayout
      title="Extensions Manager"
      planFamily={PlanFamily.PRO_PLANS}
    >
      <Grid container spacing={2}>
        {!isLocalStackRunningOrRebooting &&
          <Grid item xs={12}>
            <NotRunningAlert />
          </Grid>
        }
        <Grid item xs={12} style={{ marginBottom: '1rem' }}>
          <Typography variant='h5'>
            Extend the functionality of LocalStack by adding new services and features via our extension mechanism.
          </Typography>
          <Typography variant='h5'>
            Learn more about extensions and how they work in
            {' '}
            <a
              href={ExternalLink.DOCS_EXTENSIONS}
              target='_blank' rel='noreferrer'
            >
              our documentation
            </a>
            .
          </Typography>
        </Grid>
        {showAlert && (
          <Grid item xs={12}>
            <Alert
              severity='warning'
              style={{ width: '100%' }}
            >
              {
                PublishedExtensionAlert ||
                VersionMismatchAlert ||
                NotProInstanceAlert
              }
            </Alert>
          </Grid>
        )}

        <LoadingFragment
          loading={loadingPublishedExtensions}
          variant="grid"
          size={9}
          gridItemBreakpoints={gridItemBreakpoints()}
          height={170}
        >
          {
            sortedDisplayedExtensions?.map(
              (extension) =>
                <ExtensionCard
                  key={extension.name}
                  extension={extension}
                  onInstall={() => onInstall(extension.name)}
                  onUninstall={() => onUninstall(extension.name)}
                  installed={isExtensionInstalled(extension.name)}
                  isUpdating={[mutatingExtension].includes(extension.name)}
                  disableManage={disableManage}
                  logOutput={lastExtension === extension.name ? logs : undefined}
                  highlighted={queryParamExtension === extension.name}
                  hasError={hasManagingExtensionError && !managingExtension}
                />,
            )
          }
        </LoadingFragment>
      </Grid>
    </CustomerLayout>
  );
};
