import { SubscriptionService, useApiGetter, useRoutes } from '@localstack/services';
import { Box, Button, Card, CardContent, Grid, Skeleton, Typography } from '@mui/material';
import { ReactElement } from 'react';

import { Permission, ProductFeature } from '@localstack/types';

import { useAuthProvider } from '~/hooks';
import { AppRoute } from '~/config';

import { ManageSubscriptionCITokens } from './ManageCIToken';

export const CITokenList = (): ReactElement => {
  const { data: subscriptionCiKeys, isLoading: isApiKeysLoading } = useApiGetter(SubscriptionService, 'listCiKeys', []);
  const { can, hasFeature } = useAuthProvider();
  const { goto } = useRoutes();

  const canReadTokens = can(Permission.READ_CI_KEYS);
  const hasSubscription = hasFeature(ProductFeature.FEATURE_LOCALSTACK_PRO);

  if (isApiKeysLoading) {
    return (
      <>
        <Grid item xs={12}>
          <Skeleton variant="rounded" height={240} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rounded" height={240} />
        </Grid>
      </>
    );
  }

  if (!hasSubscription || !canReadTokens) {
    return (
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              gap={1}
              paddingY={5}
            >
              <Typography variant="h6" align="center" fontWeight={600}>
                CI Auth Tokens
              </Typography>
              {!hasSubscription ? (
                <>
                  <Typography variant="body1" align="center" color="text.secondary">
                    A subscription is required to create and use CI Auth Tokens.
                  </Typography>
                  <Button variant="contained" color="primary" onClick={() => goto(AppRoute.PRICING)}>
                    Explore subscription options
                  </Button>
                </>
              ) : (
                <Typography variant="body1" align="center" color="text.secondary">
                  You don&apos;t have the permissions required to view or manage CI Auth Tokens.
                  Reach out to the workspace admin to review your permissions
                </Typography>
              )}
            </Box>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  return (
    <>
      {subscriptionCiKeys?.map(token => (
        <ManageSubscriptionCITokens subscriptionCITokens={token} key={token.subscription_id} />
      ))}
    </>
  );
};
