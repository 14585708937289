import { DEFAULT_REDSHIFT_ROUTES } from '@localstack/constants';
import { ReactElement, useState } from 'react';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { Button, Card } from '@mui/material';
import { RedshiftClustersTable, ConfirmableButton, PageTitle, Dropdown } from '@localstack/ui';

import { RedshiftProps } from './types';

export const RedshiftClusters = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_REDSHIFT_ROUTES,
}: RedshiftProps): ReactElement => {
  const { goto } = useRoutes();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { data: clusters, isLoading, mutate } = useAwsGetter(
    'Redshift',
    'describeClusters',
    [],
    { clientOverrides },
  );

  const { deleteCluster } = useAwsEffect('Redshift', ['deleteCluster'], {
    revalidate: ['describeClusters'],
    clientOverrides,
  });
  const handleRemove = async () => {
    await Promise.all(
      selectedIds.map(async (id) =>
        deleteCluster({ ClusterIdentifier: id }),
      ),
    );
  };

  return (
    <Layout
      title={<PageTitle title="Redshift Clusters" onMutate={mutate} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_REDSHIFT_CLUSTER_CREATE)}>
            Create Cluster
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Cluster(s)?`}
              onClick={handleRemove}
              text="Selected Clusters will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <RedshiftClustersTable
          clusters={clusters || {}}
          onViewCluster={(id: string) =>
            goto(routes.RESOURCES_REDSHIFT_CLUSTER, { id })
          }
          loading={isLoading}
          onSelect={setSelectedIds}
        />
      </Card>
    </Layout>
  );
};
