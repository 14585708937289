import { Palette } from '@mui/material/styles';

import { StyleRules } from '@mui/styles';

import { ThemeType } from '../../constants';

// needs CssBaseline component after ThemeProvider

export default (palette: Palette): Partial<StyleRules> => ({
  styleOverrides: {
    body: {
      fontSize: '1rem',
      lineHeight: 1.143,
      letterSpacing: 'normal',
    },
    html: {
      '*::-webkit-scrollbar': {
        width: 8,
      },
      '*::-webkit-scrollbar-track': {
        background: palette.mode === ThemeType.DARK ? palette.background.default : palette.grey[200],
        borderRadius: '10px !important',
      },
      '*::-webkit-scrollbar-thumb': {
        background: palette.mode === ThemeType.DARK ? palette.background.paper : palette.grey[400],
        borderRadius: '10px !important',
      },
    },
  },
});
