import { DEFAULT_REDSHIFT_ROUTES } from '@localstack/constants';
import { ReactElement } from 'react';
import { useAwsGetter, useRoutes } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Box, Button, Card, Typography } from '@mui/material';
import { Breadcrumbs, RedshiftClusterDetail } from '@localstack/ui';

import { RedshiftProps } from './types';

export const RedshiftCluster = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_REDSHIFT_ROUTES,
}: RedshiftProps): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();
  const { data: clusters } = useAwsGetter('Redshift', 'describeClusters', [{ ClusterIdentifier: id }], {
    clientOverrides,
  });

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Redshift Cluster Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Redshift', () => goto(routes.RESOURCES_REDSHIFT_CLUSTERS)],
              [id, null],
            ]}
          />
        </Box>
      }
      actions={<Button onClick={() => goto(routes.RESOURCES_REDSHIFT_CLUSTER_UPDATE, { id })}>Edit Cluster</Button>}
    >
      <Card>
        <RedshiftClusterDetail cluster={clusters?.Clusters?.[0]} />
      </Card>
    </Layout>
  );
};
