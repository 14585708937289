import { AxiosRequestConfig } from 'axios';
import { DEFAULT_RESOURCE_BASE_URL } from '@localstack/constants';
import Backup from 'aws-sdk/clients/backup';
import TimestreamWrite from 'aws-sdk/clients/timestreamwrite';
import TimestreamQuery from 'aws-sdk/clients/timestreamquery';
import ApiGatewayV2 from 'aws-sdk/clients/apigatewayv2';
import AppSync from 'aws-sdk/clients/appsync';
import EventBridge from 'aws-sdk/clients/eventbridge';
import Kafka from 'aws-sdk/clients/kafka';
import RDSDataService from 'aws-sdk/clients/rdsdataservice';
import StepFunctions from 'aws-sdk/clients/stepfunctions';
import SESV2 from 'aws-sdk/clients/sesv2';
import Glue from 'aws-sdk/clients/glue';
import ECR from 'aws-sdk/clients/ecr';
import SGM from 'aws-sdk/clients/sagemaker';
import Neptune from 'aws-sdk/clients/neptune';
import CloudControl from 'aws-sdk/clients/cloudcontrol';

import {
  Athena,
  APIGateway,
  DynamoDB,
  IAM,
  S3,
  CloudTrail,
  CloudFormation,
  CognitoIdentityServiceProvider,
  CloudWatch,
  CloudWatchLogs,
  CloudFront,
  RDS,
  SQS,
  SNS,
  SSM,
  Lambda,
  SecretsManager,
  ECS,
  ElastiCache,
  Kinesis,
  KMS,
  SES,
  EC2,
  ELB,
  ELBv2,
  MQ,
  ApplicationAutoScaling,
  Route53,
  Route53Resolver,
  Fis,
  OpenSearch,
  QLDB,
  DocDB,
  AppConfig,
  CognitoIdentity,
  ACM,
  MWAA,
  Amplify,
  EKS,
  TranscribeService,
  CodeCommit,
  CostExplorer,
  Account,
  DMS,
  Pipes,
  DynamoDBStreams,
  Redshift,
} from 'aws-sdk';

import { LocalStack } from './apis';

export const AWS_CLIENTS = {
  // LocalStack internal services
  LocalStack,
  // AWS SDK services
  Athena,
  APIGateway,
  ApiGatewayV2,
  IAM,
  DynamoDB,
  DynamoDBDocument: DynamoDB.DocumentClient,
  AppSync,
  S3,
  SQS,
  SNS,
  CloudFormation,
  CloudTrail,
  CognitoIdentityServiceProvider,
  CloudWatch,
  CloudWatchLogs,
  CloudFront,
  Backup,
  RDS,
  RDSDataService,
  StepFunctions,
  SSM,
  Lambda,
  SecretsManager,
  ECS,
  ElastiCache,
  EventBridge,
  Kinesis,
  Kafka,
  KMS,
  SES,
  TimestreamQuery,
  TimestreamWrite,
  EC2,
  ELB,
  ELBv2,
  SESV2,
  MQ,
  Glue,
  ECR,
  Neptune,
  SGM,
  ApplicationAutoScaling,
  Route53,
  Route53Resolver,
  Fis,
  OpenSearch,
  QLDB,
  DocDB,
  AppConfig,
  CognitoIdentity,
  ACM,
  MWAA,
  Amplify,
  EKS,
  TranscribeService,
  CodeCommit,
  CostExplorer,
  Account,
  DMS,
  CloudControl,
  Pipes,
  DynamoDBStreams,
  Redshift,
};

export const AWS_SERVICE_PORTS: Record<keyof typeof AWS_CLIENTS, number> = {
  LocalStack: 4566,
  Athena: 4566,
  APIGateway: 4566,
  ApiGatewayV2: 4566,
  DynamoDB: 4566,
  DynamoDBDocument: 4566,
  IAM: 4566,
  AppSync: 4566,
  S3: 4566,
  SQS: 4566,
  SNS: 4566,
  CloudFormation: 4566,
  CloudTrail: 4566,
  CognitoIdentityServiceProvider: 4566,
  CloudWatch: 4566,
  CloudWatchLogs: 4566,
  CloudFront: 4566,
  Backup: 4566,
  RDS: 4566,
  RDSDataService: 4566,
  StepFunctions: 4566,
  SSM: 4566,
  Lambda: 4566,
  SecretsManager: 4566,
  ECS: 4566,
  ElastiCache: 4566,
  EventBridge: 4566,
  Kinesis: 4566,
  Kafka: 4566,
  KMS: 4566,
  SES: 4566,
  TimestreamQuery: 4566,
  TimestreamWrite: 4566,
  EC2: 4566,
  ELB: 4566,
  ELBv2: 4566,
  SESV2: 4566,
  MQ: 4566,
  Glue: 4566,
  ECR: 4566,
  Neptune: 4566,
  SGM: 4566,
  ApplicationAutoScaling: 4566,
  Route53: 4566,
  Route53Resolver: 4566,
  Fis: 4566,
  OpenSearch: 4566,
  QLDB: 4566,
  DocDB: 4566,
  AppConfig: 4566,
  CognitoIdentity: 4566,
  ACM: 4566,
  MWAA: 4566,
  Amplify: 4566,
  EKS: 4566,
  TranscribeService: 4566,
  CodeCommit: 4566,
  CostExplorer: 4566,
  Account: 4566,
  DMS: 4566,
  CloudControl: 4566,
  Pipes: 4566,
  DynamoDBStreams: 4566,
  Redshift: 4566,
};

// do not modify this constant directly, use "configureClient" instead
export const API_CONFIGURATION = {
  baseUrl: '',
  isSsl: true,
  getLocalEndpoint: (): string => DEFAULT_RESOURCE_BASE_URL,
  getAuthHeaders: ():
    | AxiosRequestConfig['headers']
    | Promise<AxiosRequestConfig['headers']> => ({}),
};

export const DEFAULT_SWR_SETTINGS = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
};
