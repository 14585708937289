import { BASE_PATH } from '@localstack/constants';

import { AWS_CLIENTS } from '../config';

const END_PATH = '.normal.json';

const ENDPOINTS: Record<keyof typeof AWS_CLIENTS, string> = {
  LocalStack: '',
  Lambda: 'lambda-2015-03-31',
  APIGateway: 'apigateway-2015-07-09',
  ApiGatewayV2: 'apigatewayv2-2018-11-29',
  AppSync: 'appsync-2017-07-25',
  Athena: 'athena-2017-05-18',
  Backup: 'backup-2018-11-15',
  CloudTrail: 'cloudtrail-2013-11-01',
  CloudWatchLogs: 'logs-2014-03-28',
  CloudFront: 'cloudfront-2020-05-31',
  CognitoIdentityServiceProvider: 'cognito-idp-2016-04-18',
  DynamoDB: 'dynamodb-2012-08-10',
  ECS: 'ecs-2014-11-13',
  ElastiCache: 'elasticache-2015-02-02',
  EventBridge: 'eventbridge-2015-10-07',
  IAM: 'iam-2010-05-08',
  Kafka: 'kafka-2018-11-14',
  Kinesis: 'kinesis-2013-12-02',
  KMS: 'kms-2014-11-01',
  CloudWatch: 'monitoring-2010-08-01',
  RDS: 'rds-2014-10-31',
  SecretsManager: 'secretsmanager-2017-10-17',
  SES: 'email-2010-12-01',
  SESV2: 'sesv2-2019-09-27',
  SNS: 'sns-2010-03-31',
  SQS: 'sqs-2012-11-05',
  StepFunctions: 'states-2016-11-23',
  SSM: 'ssm-2014-11-06',
  S3: 's3-2006-03-01',
  DynamoDBDocument: 'dynamodb-2012-08-10',
  CloudFormation: 'cloudformation-2010-05-15',
  RDSDataService: 'rdsdataservice-2018-08-01',
  TimestreamQuery: 'timestreamquery-2018-11-01',
  TimestreamWrite: 'timestream-write-2018-11-01',
  EC2: 'ec2-2016-11-15',
  ELB: 'elasticloadbalancing-2012-06-01',
  ELBv2: 'elasticloadbalancingv2-2015-12-01',
  ECR: 'ecr-2015-09-21',
  MQ: 'mq-2017-11-27',
  Glue: 'glue-2017-03-31',
  Neptune: 'neptune-2014-10-31',
  SGM: 'sagemaker-2017-07-24',
  ApplicationAutoScaling: 'application-autoscaling-2016-02-06',
  Route53: 'route53-2013-04-01',
  Route53Resolver: 'route53resolver-2018-04-01',
  Fis: 'fis-2020-12-01',
  OpenSearch: 'opensearch-2021-01-01',
  QLDB: 'qldb-2019-01-02',
  DocDB: 'docdb-2014-10-31',
  AppConfig: 'appconfig-2019-10-09',
  CognitoIdentity: 'cognito-identity-2014-06-30',
  ACM: 'acm-2015-12-08',
  MWAA: 'mwaa-2020-07-01',
  Amplify: 'amplify-2017-07-25',
  EKS: 'eks-2017-11-01',
  TranscribeService: 'transcribe-2017-10-26',
  CodeCommit: 'codecommit-2015-04-13',
  CostExplorer: 'ce-2017-10-25',
  Account: 'account-2021-02-01',
  DMS: 'dms-2016-01-01',
  CloudControl: 'cloudcontrol-2021-09-30',
  Pipes: 'pipes-2015-10-07',
  DynamoDBStreams: 'streams.dynamodb-2012-08-10',
  Redshift: 'redshift-2012-12-01',
};

export const getSchema = (resource: keyof typeof ENDPOINTS): string =>
  `${BASE_PATH}templates/${ENDPOINTS[resource]}${END_PATH}`;
