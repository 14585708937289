import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const REDSHIFT_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.REDSHIFT}`;

export const DEFAULT_REDSHIFT_ROUTES: Record<string, string> = {
  RESOURCES_REDSHIFT_CLUSTERS: `${REDSHIFT_BASE_ROUTE}/clusters`,
  RESOURCES_REDSHIFT_CLUSTER: `${REDSHIFT_BASE_ROUTE}/cluster/:id`,
  RESOURCES_REDSHIFT_CLUSTER_CREATE: `${REDSHIFT_BASE_ROUTE}/cluster/new`,
  RESOURCES_REDSHIFT_CLUSTER_UPDATE: `${REDSHIFT_BASE_ROUTE}/cluster/:id/update`,
};
