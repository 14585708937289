import { GLOBAL_ROUTES } from '@localstack/constants';


export const RESOURCES_TYPES = {
  lambda: GLOBAL_ROUTES.RESOURCES_LAMBDA_FUNCTIONS,
  'cognito-idp': GLOBAL_ROUTES.RESOURCES_COGNITO,
  'cognito-identity': GLOBAL_ROUTES.RESOURCES_COGNITO_IDENTITY_POOLS,
  cloudformation: GLOBAL_ROUTES.RESOURCES_CLOUDFORMATION_STACKS,
  apigateway: GLOBAL_ROUTES.RESOURCES_AGW1,
  apigatewayv2: GLOBAL_ROUTES.RESOURCES_AGW2,
  appsync: GLOBAL_ROUTES.RESOURCES_APPSYNC,
  logs: GLOBAL_ROUTES.RESOURCES_CLOUDWATCH_GROUPS,
  cloudwatch: GLOBAL_ROUTES.RESOURCES_CLOUDWATCH_MONITORING,
  dynamodb: GLOBAL_ROUTES.RESOURCES_DYNAMODB,
  sqs: GLOBAL_ROUTES.RESOURCES_SQS_QUEUES,
  sns: GLOBAL_ROUTES.RESOURCES_SNS_TOPICS,
  s3: GLOBAL_ROUTES.RESOURCES_S3,
  stepfunctions: GLOBAL_ROUTES.RESOURCES_STEPFUNCTIONS,
  athena: GLOBAL_ROUTES.RESOURCES_ATHENA_DATABASES,
  ses: GLOBAL_ROUTES.RESOURCES_SES1_IDENTITIES,
  sesv2: GLOBAL_ROUTES.RESOURCES_SES2_IDENTITIES,
  ssm: GLOBAL_ROUTES.RESOURCES_SYSTEM_PARAMETERS,
  cloudtrail: GLOBAL_ROUTES.RESOURCES_CLOUDTRAIL_EVENTS,
  'timestream-write': GLOBAL_ROUTES.RESOURCES_TIMESTREAM,
  rds: GLOBAL_ROUTES.RESOURCES_RDS_DATABASES,
  secretsmanager: GLOBAL_ROUTES.RESOURCES_SECRETS_MANAGER,
  events: GLOBAL_ROUTES.RESOURCES_EVENT_BRIDGE,
  kms: GLOBAL_ROUTES.RESOURCES_KMS,
  kinesis: GLOBAL_ROUTES.RESOURCES_KINESIS,
  kafka: GLOBAL_ROUTES.RESOURCES_KAFKA,
  iam: GLOBAL_ROUTES.RESOURCES_IAM,
  elasticache: GLOBAL_ROUTES.RESOURCES_ELASTICACHE,
  backup: GLOBAL_ROUTES.RESOURCES_BACKUP_PLANS,
  ecs: GLOBAL_ROUTES.RESOURCES_ECS,
  ec2: GLOBAL_ROUTES.RESOURCES_EC2,
  ecr: GLOBAL_ROUTES.RESOURCES_ECR_REPOSITORIES,
  glue: GLOBAL_ROUTES.RESOURCES_GLUE,
  mq: GLOBAL_ROUTES.RESOURCES_MQ_BROKERS,
  sagemaker: GLOBAL_ROUTES.RESOURCES_SGM_MODELS,
  'application-autoscaling': GLOBAL_ROUTES.RESOURCES_APPLICATION_AUTO_SCALING,
  cloudfront: GLOBAL_ROUTES.RESOURCES_CLOUDFRONT_DISTRIBUTIONS,
  route53: GLOBAL_ROUTES.RESOURCES_ROUTE53,
  neptune: GLOBAL_ROUTES.RESOURCES_NEPTUNE_INSTANCES,
  elb: GLOBAL_ROUTES.RESOURCES_EC2_LBV1,
  elbv2: GLOBAL_ROUTES.RESOURCES_EC2_LBV2,
  opensearch: GLOBAL_ROUTES.RESOURCES_OPENSEARCH_DOMAINS,
  qldb: GLOBAL_ROUTES.RESOURCES_QLDB_LEDGERS,
  docdb: GLOBAL_ROUTES.RESOURCES_DOCDB_CLUSTERS,
  appconfig: GLOBAL_ROUTES.RESOURCES_APPCONFIG_APPLICATIONS,
  acm: GLOBAL_ROUTES.RESOURCES_ACM_CERTIFICATES,
  mwaa: GLOBAL_ROUTES.RESOURCES_MWAA_ENVIRONMENTS,
  amplify: GLOBAL_ROUTES.RESOURCES_AMPLIFY_APPS,
  eks: GLOBAL_ROUTES.RESOURCES_EKS_CLUSTERS,
  transcribe: GLOBAL_ROUTES.RESOURCES_TRANSCRIBE_TRANSCRIPTION_JOBS,
  codecommit: GLOBAL_ROUTES.RESOURCES_CODECOMMIT_REPOSITORIES,
  ce: GLOBAL_ROUTES.RESOURCES_CE_COST_CATEGORY_DEFINITIONS,
  account: GLOBAL_ROUTES.RESOURCES_ACCOUNT_CONTACTINFO,
  dms: GLOBAL_ROUTES.RESOURCES_DMS_REPLICATION_INSTANCES,
  pipes: GLOBAL_ROUTES.RESOURCES_EVENT_BRIDGE_PIPES,
  redshift: GLOBAL_ROUTES.RESOURCES_REDSHIFT_CLUSTERS,
};

export type ResourceGroups = [string, (keyof typeof RESOURCES_TYPES)[]][][]

// try to keep more or less the same number of services under each column
export const RESOURCE_GROUPS: ResourceGroups = [
  [
    ['App Integration',
      ['apigateway', 'sqs', 'sns', 'stepfunctions', 'events', 'application-autoscaling', 'mwaa', 'mq', 'pipes']],
    ['Compute', ['ec2', 'lambda', 'ecs', 'ecr', 'eks']],
  ],
  [
    ['Management/Governance', ['cloudformation', 'logs', 'cloudwatch', 'ssm', 'cloudtrail', 'account']],
    ['Business Applications', ['ses']],
    ['Developer Tools', ['appconfig', 'codecommit']],
    ['Front-end Web & Mobile', ['appsync', 'amplify']],
    ['Migration and transfer', ['dms']],
  ],
  [
    ['Security Identity Compliance', ['cognito-idp', 'secretsmanager', 'kms', 'iam', 'acm']],
    ['Storage', ['s3', 'backup']],
    ['Machine Learning', ['sagemaker', 'transcribe']],
    ['Cloud Financial Management', ['ce']],
  ],
  [
    ['Database', ['dynamodb', 'rds', 'elasticache', 'timestream-write', 'qldb', 'docdb', 'neptune']],
    ['Analytics', ['athena', 'kinesis', 'kafka', 'glue', 'route53', 'cloudfront', 'opensearch', 'redshift']],
  ],

];
